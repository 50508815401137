import { faCirclePlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';



const ReleaseData = () => {
    
    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState(Array());
    const [candidates, setCandidates] = useState(Array());

    const [item, setItem] = useState(0);



    //initial fetch of data
    const fetchData = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('getCompletedTickets.php', data)
                .then((res => {
                    setTickets(res.data.tickets);
                    setCandidates(res.data.candidates);
                }))
                .catch((err) => {
                    
                })
                .finally(() => {
                    
                });

    }
   
    useEffect(() => {
        fetchData();
    }, []);
    

    const createNewCandidate = () => {
        
        const product = document.getElementById("newReleaseCandidateProduct").value;
        const version = document.getElementById("newReleaseCandidateVersion").value;

        if(product === "" || version === ""){
            alert("options cannot be blank.")
        } else {


        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const user = JSON.parse(Cookies.get('_auth_state')).name;
        const data = JSON.stringify({Token: token, Email: email, Data: {product:product, version:version}});
        axios.post('createReleaseCandidate.php', data)        

        .then((res) => {

            if(res.data.status === "Error" || res.data.status === "Option Exists Already"){
                alert("Error adding new candidate. Check console")
                console.log(res.data)
            } else {
                // Some notification to confirm candidate added
                document.getElementById("newReleaseCandidateVersion").value = "";
            }


        })
        .catch((err) => {
            alert(err);
        })
        .then(() => {
            fetchData();
        })
         
    }

    }

    const updateTicketCandidate = (id, val) => {

        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const user = JSON.parse(Cookies.get('_auth_state')).name;
        const data = JSON.stringify({Token: token, Email: email, Data: {id:id, val:val}});
        axios.post('updateTicketReleaseData.php', data)        

        .then((res) => {
            console.log(res.data)
        })
        .catch((err) => {
            alert(err);
        })
        .then(() => {
           fetchData();
        })

    }
    
    return ( <>

        <div className="newReleaseCandidate">
            Add New Release Option for &nbsp;&nbsp;&nbsp;
            <select id="newReleaseCandidateProduct">
                <option value="Web App">Osprey Web App</option>
                <option value="Desktop App">Osprey Desktop App</option>
                <option value="Outlook COM Addin">Outlook COM Addin</option>
                <option value="Outlook 365 Addin">Outlook 365 Addin</option>
                <option value="Convert 2 Client">Convert 2 Client</option>
                <option value="Client Web Portal">Client Web Portal</option>
                <option value="Client Mobile Portal">Client Mobile Portal</option>
                <option value="Gateway">Gateway</option>
                <option value="RDS Gateway">RDS Gateway</option>
                <option value="Word COM Addin">Word COM Addin</option>
                <option value="Word 365 Addin">Word 365 Addin</option>
            </select> &nbsp;&nbsp;&nbsp;version &nbsp;&nbsp;&nbsp;
            <input type="text" placeholder="Version..." id="newReleaseCandidateVersion"/>
            &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon style={{"color":"green", "pointer":"cursor"}} icon={faCirclePlus} onClick={()=>{createNewCandidate()}} />
        </div>

    <div className="ticketReleaseData">
        <h4>Tickets</h4>
        {tickets.length === 0 && <><p>No complete tickets</p></>}
        {tickets.length !== 0 && tickets.map((ticket) => (
            
            <div className="ticketContainer" key={ticket.id}>
                <div className="ticketHeader">
                    {ticket.product}<br></br>
                    {ticket.id} - {ticket.subject}
                <div className="selector">
                    <select name="candidate" id={"selector_" + ticket.id}>
                        <option value="0">Data Fix / Site Config</option>
                    {candidates.map((candidate) => 
                        <option key={candidate.id} value={candidate.id}>{candidate.product} - v{candidate.version}</option>
                    )}
                    </select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon style={{"color":"white", "pointer":"cursor"}} onClick={()=>{updateTicketCandidate(ticket.id, document.getElementById("selector_" + ticket.id).value)}} icon={faSave} />
                </div>


                </div>
            </div>

        ))}
    </div>



    <div className="releaseDates">

        <h4>Release Candidates</h4>

        {candidates.map(candidate => 
            <>
            {candidate.product !== "Data Fix / Site Config" && candidate.product !== "PRE-PLANNER RELEASE" && candidate.releaseDate === "0000-00-00" &&
            <>
                <div key={candidate.id}>
                    {candidate.product}
                    {candidate.version}
                    <input type="date" />
                </div>
            </>
            }
            </>
            )}


    </div>

    
    
    </> );
}
 
export default ReleaseData;