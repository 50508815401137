import axios from 'axios';
import { useState } from 'react';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';



const Login = () => {
    //set states
    const [error, setError] = useState(null);
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    const [loadingSignIn, setLoadingSignIn] = useState(false);

    //toggle password show or hide
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    //submit login form data for verification
    const handleSubmit = (e) => {
        e.preventDefault();
        const username = document.getElementById("username").value
        const password = document.getElementById("password").value
        const data = JSON.stringify({ username: username, password: password});

        axios.post("/auth.php", data)
            .then((res) => {
                let response = res.data.Response
                if (response == "Ok"){
                    setLoadingSignIn(true);
                    signIn(
                        {
                            token: res.data.jwt,
                            expiresIn: 1200,
                            tokenType: "Bearer",
                            authState: { email: res.data.userEmail, name: res.data.userName}
                        }
                    )

                    const timer = setTimeout(()=> navigate("/"),2500);
                    
                } else {
                    setError(res.data.Response)
                }
            })
    }


    return ( 
     <div className="login">

        {loadingSignIn && <div className="loadingSignIn"><img src="loading.gif" /><br></br><h3>Logging in...</h3></div>}


        <div className="loginColumn">
            {error && <div className="loginError">{error}</div>}
            <div className="loginForm">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <input className="loginFormInput" type="text" id="username" required />
                    <input className="loginFormInput" type={passwordShown ? "text" : "password"} id="password" palceholder="enter password..." required /><p style={{cursor:"pointer"}}onClick={togglePassword}>Show Password</p>
                    <button className="loginFormButton" type="submit">Submit</button>
                    <p>
                        <small>v{process.env.REACT_APP_VERSION}</small>
                    </p>
                    
                </form>
             </div>
        </div>
        <div className="loginColumn blockColour">
            <h2 className="loginTitle">
                <img src="ospreylogowhite.png" style={{width:"67%"}}></img>
                <br></br>
                Development<br></br>Tracking & Planning
            </h2>
        </div>
     </div> 
    );
}
 
export default Login;