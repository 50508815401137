const Users = () => {
    return ( 

        <div>
            <h2>Users</h2>
        </div>

     );
}
 
export default Users;