import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";


const NewCustomItem = () => {

    const [success, setSuccess] = useState(false);
    const [newId, setNewId] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;

           var subject = e.target.subject.value
           var brief = e.target.brief.value
           var type = e.target.type.value
           var urls = e.target.urls.value
           var status = e.target.status.value
        const data = JSON.stringify({Token: token, Email: email, Data: {subject: subject, brief: brief, type:type, urls:urls, status: status}});


        axios.post('createCustomItem.php', data)
            .then((res => {
                console.log(res.data)

                document.getElementById("subject").value = "";
                document.getElementById("brief").value = "";
                document.getElementById("urls").value = "";
                setSuccess(true);
                setNewId(res.data.Id);


            }))
            .catch((err => {
                alert("Error Adding Custom Item. Please View Console for More Details.")
                console.log(err.response.data)
            }))
            .finally(()=>{

            })



    }

    return ( <>
    
   
        <h3>Create a Custom Item</h3>

        {success && <div className="newItemCreated">New Item Added. ID {newId}</div>}

<div className="newitemContainer">
     <form onSubmit={handleSubmit}>
        Subject<br></br>
        <input id="subject" name="subject" type="text" required/><br></br><br></br>
        Steps/Brief<br></br>
        <textarea name="brief" id="brief" cols="80" rows="6" required></textarea><br></br><br></br>


        Type<br></br>
        <select name="type" id="">
            <option value="new development">New Development</option>
            <option value="wish">Wish</option>
            <option value="investigation">Investigation</option>
            <option value="issue">Issue</option>
        </select><br></br><br></br>


        Client URLS (Separated by ;)<br></br>
        <input id="urls" name="urls" type="text" /><br></br><br></br>

        Submit To<br></br>
        <select name="status" id="">
            <option value="reviewed">Planner</option>
            <option value="new">Requests</option>
        </select>
        <br></br><br></br>
        <button type="submit">Submit</button>
    </form>

</div>

    
    </> );
}
 
export default NewCustomItem;