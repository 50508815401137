import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";


const Releases = () => {

    //set states
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const [releases, setReleases] = useState(Array());
const [activeRelease, setActiveRelease] = useState(null);
const [activeReleaseName, setActiveReleaseName] = useState(null);


//intial fetch of data
const fetchData = () => {

    axios
        .get("getReleases.php")
        .then((res => {
            setReleases(res.data)
        }))
        .catch((err => {
            setError(err)
        }))
        .finally(() => {
            setLoading(false)
        })
}

useEffect(() => {
    fetchData();
}, []);


const initContextMenu = (release, e) => {
    console.log(e, release.id)    
    setActiveRelease(release.id)
    setActiveReleaseName(release.description);
        var z = document.getElementById("contextMenu").style.display;
        if(z == "none"){
            if(release.isSystem != 1){
                        document.getElementById("contextHeader").innerHTML = release.description
                        document.getElementById("contextMenu").style.top = (e.pageY - 50) + "px"
                        document.getElementById("contextMenu").style.left = (e.pageX-200) + "px"
                        document.getElementById("contextMenu").style.display = "block"
                    } else {

                    }
        } else {
            document.getElementById("contextMenu").style.display = "none"
        }
}



const editRelease = () => {
    axios
        .get("getRelease.php?id=" + activeRelease)
        .then((res) =>
        console.log(res.data[0])

        //now update a sidebar form values and handle a submit update...

        )
        .catch((err) => 
        console.log(err)
        )
        .finally(()=>{

        })
}


const viewReleaseTickets = () => {
    
}

const commentAllReleaseTickets = () => {
    let comment = prompt("Enter your comment")
}

const initRelease = () => {

    console.log(activeRelease, activeReleaseName + " - Initiate Release")
  
}




const handleNewReleaseForm = () => {

}

const showNewReleaseForm = () => {
let z = document.getElementById("addReleaseForm").style.display

if(z == "none"){
    document.getElementById("addReleaseForm").style.display = "block"
} else {
    document.getElementById("addReleaseForm").style.display = "none"
}

}

    return ( 
        <div>
    
    { loading && <div className='loading'><img className='loadingGif' src="loading.gif" alt="loading" /><br></br><p>Loading...</p></div>}
    { error && <div>{error}</div>}
    { releases && <div>
        
      <div onClick={()=>{showNewReleaseForm()}} className="addNewReleaseButton"><h3>Add New Release</h3></div>
        
        
        <div className="addReleaseForm" id="addReleaseForm">
            <form onSubmit={handleNewReleaseForm()}>
                Description: <input type="text" required />
                Start Date: <input type="date" />
                End Date: <input type="date" />
                Dev Hours: <input type="number" />
                <button className="requestApprove">Create New Release</button>
            </form>
        </div>

        <h3>Current Releases</h3>

        <table>

                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Development Hours</th>
                <th>Versions</th>
                <th>UAT Released</th>
                <th>Full Release</th>



        {releases.map((release) => 



            <tr
            key={releases.id}
            className={"versionRow System" + release.isSystem}
            onContextMenu={(e) => {
                e.preventDefault();
                initContextMenu(release, e)
            }}
            >




                <td style={{width:"15%"}}>{release.description}</td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && new Date(release.start).toLocaleDateString("en-GB")}
                    {release.isSystem == 1 && "-"}
                </td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && new Date(release.finish).toLocaleDateString("en-GB")}
                    {release.isSystem == 1 && "-"}           
                </td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && release.devHours}
                    {release.isSystem == 1 && "-"}
                </td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && 
                        <span className="releaseVersions">
                            Browser:{release.versions.browser}<br></br>
                            App: {release.versions.app}<br></br>
                            Portal: {release.versions.portal}<br></br>
                            C2C: {release.versions.c2c}
                        </span>
                    }
                    {release.isSystem == 1 && "-"}
                </td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && release.releasedToUat == 1 && "Tick"}
                    {release.isSystem != 1 && release.releasedToUat != 1 && "Cross"}
                    {release.isSystem == 1 && "-"}
                </td>
                <td style={{width:"10%"}}>
                    {release.isSystem != 1 && release.released == 1 && "Tick"}
                    {release.isSystem != 1 && release.released != 1 && "Cross"}
                    {release.isSystem == 1 && "-"}
                </td>

            </tr>
        )}

                </table>

                
                </div>
    }



        <div className="releaseContextMenu">
            <div className="updateStatusContextMenu" id="contextMenu">
            <div className="contextHeader" id="contextHeader"></div>
            <div className="contextOption" onClick={()=>{editRelease()}}>Edit Details</div>
            <div className="contextOption" onClick={()=>{viewReleaseTickets()}}>View Tickets</div>
            <div className="contextOption" onClick={()=>{commentAllReleaseTickets()}}>Comment All Tickets</div>
            <div className="contextOption" onClick={()=>{initRelease()}}>Initiate Release</div>
            </div>
        </div>

    
        </div>
     );
}
 
export default Releases;