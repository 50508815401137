
import axios from 'axios';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCancel, faMagnifyingGlass, faTrash, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
 
const Approvals = () => {
  
    // setState
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState('');
     const [tickets, setTickets] = useState(Array())
     const [activeTicket, updateActiveTicket] = useState(null);
     const [buckets] = useState(Array("investigation", "issue", "wish"))
 
     //initial fetch of data
     const fetchData = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('approved_tickets.php', data)
                 .then((res => {
                     setTickets(res.data.tickets);
                 }))
                 .catch((err) => {
                     setError(err);
                 })
                 .finally(() => {
                     setLoading(false);
                 });
 
     }
    
     useEffect(() => {
         fetchData();
     }, []);
 
 
 // update ticket set when changes are made
 
     const UpdateTickets = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('approved_tickets.php', data)
         .then((res => {
             setLoading(false);
             setTickets(res.data.tickets);
         }))
         .catch((err) => {
             setError(err);
         })
         .finally(() => {
             setLoading(false);
         });
         
      }


      // api post to update the priority of the ticket
      const updatePriority = (priority, id) => {
  
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {id: id, priority: priority}});
        
        axios.post('updatePriority.php', data)
              .then((res => {
                  if(res.data.Status == "Success"){
                      console.log(res.data.Status)
                      document.getElementById("approvalSideBarPriority").innerHTML = "Priority " + priority;
                      document.getElementById("priorityMenu").style.display = "none";
  
                      UpdateTickets();
                      updateModal(id);
                                      } else {
                      alert(res.data.Response)
                  }
              }))
              .catch((err) => 
              alert(err)
              )
      }
 
 
 
      //update the sidebar modal on the fly
     const updateModal = (id) => {
         document.getElementById("priorityMenu").style.display = "none"
         updateActiveTicket(id);
         document.getElementById("approvalSideBar").style.display = "block";
        
         const token = Cookies.get('_auth');
         const email = JSON.parse(Cookies.get('_auth_state')).email;
         const data = JSON.stringify({Token: token, Email: email, Data: {id: id}});
         
         axios.post('ticketData.php', data)
              .then((res => {
                     document.getElementById("approvalSideBarId").innerHTML = res.data.tickets[0].id;
                     document.getElementById("approvalSideBarTitle").innerHTML = res.data.tickets[0].subject;
                     document.getElementById("approvalSideBarType").innerHTML = res.data.tickets[0].type;
                     document.getElementById("approvalSideBarCreateText").innerHTML = res.data.tickets[0].createdText;
                     document.getElementById("approvalSideBarApprovedText").innerHTML = 'Aproved by ' + res.data.tickets[0].approvedText;
                     document.getElementById("approvalSideBarClients").innerHTML = res.data.tickets[0].clients;
                     document.getElementById("approvalSideBarSteps").innerHTML = res.data.tickets[0].steps;
                     document.getElementById("approvalSideBarError").innerHTML = res.data.tickets[0].error;
                     document.getElementById("approvalSideBarPreferredOutcome").innerHTML = res.data.tickets[0].preferredOutcome;
                     document.getElementById("approvalSideBarPriority").innerHTML = 'Priority ' + res.data.tickets[0].priority;
                    document.getElementById("hiddenId").value = activeTicket;

                    let productAreas = res.data.tickets[0].products;
                    productAreas = productAreas.replaceAll(";", "<br>");
                    productAreas = productAreas.replaceAll("__", " > ");
                    productAreas = productAreas.replaceAll("_", " ");

                    document.getElementById("approvalSideBarProducts").innerHTML = productAreas;

                     let areaText = res.data.tickets[0].area;
                     areaText = areaText.replaceAll("__", " > ");
                     areaText = areaText.replaceAll("_", " ");
                     document.getElementById("approvalSideBarArea").innerHTML = areaText;
                 }))
                 .catch((err => {
                     alert(err);
                 }))
         } 
 
 
 
     // X to close the modal
     const closeModal = () => {
         document.getElementById("approvalSideBar").style.display = "none"
     }
 
 
    // handle submit form from sidebar
     function submitForm (e) {
        e.preventDefault();
        let ticketId = activeTicket;
        let ticketTime = e.target[1].value;
        let ticketComplexity = e.target[2].value;
        let ticketRisk = e.target[3].value;    

        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {id: ticketId, time: ticketTime, complexity: ticketComplexity, risk: ticketRisk}});
        axios.post('updateApproval.php', data)
            .then((res =>{
                UpdateTickets()
                document.getElementById("hiddenId").value = "";
                document.getElementById("approvalTime").value = "";
                document.getElementById("approvalComplexity").value = "";
                document.getElementById("approvalRisk").value = "";
                document.getElementById("approvalSideBar").style.display = "none";


                //create event comment and update zendesk
                const newComment = "Ticket has been authorised for future planning";
                const user = JSON.parse(Cookies.get('_auth_state')).name;
                const token = Cookies.get('_auth');
                const email = JSON.parse(Cookies.get('_auth_state')).email;
                const data = JSON.stringify({Token: token, Email: email, Data: {user:user, comment:newComment, id: activeTicket, type:"Event"}});
                axios.post('createComment.php', data)
                  .catch((err => {
                    alert(err)
                  }))

             }))
             .catch((err => {
                alert(err)
             }))
    }
 

    const deleteRequest = (ticket) => {
        document.getElementById("deleteName").innerHTML = ticket.deleteRequest;
        document.getElementById("deleteId").innerHTML = ticket.id;
        document.getElementById("deleteRequestContainer").style.display = "block";
      }
      
      
      const permitDelete = (id) => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data:{ticket:id}});
      
        axios
          .post("permitDeletion.php", data)
          .then((res) => 
            {if(res.data.update === "SQL Ok. Zendesk Ok."){console.log("success")} else {console.log("error")}}
          )
          .catch((err) => {
            alert(err);
          })
          .finally(()=>{
            document.getElementById("deleteRequestContainer").style.display = "none";
            UpdateTickets();
          })
      }
 
   return (
 
         <div className="impDash">
 
         
 
       
         { loading && <div className='loading'><img className='loadingGif' src="loading.gif" alt="loading" /><br></br><p>Loading...</p></div>}
         { error && <div>{error}</div>}
         { tickets && tickets.count === 0 && <div><h4>No Tickets to Approve.</h4></div>}
         { tickets && tickets.count !== 0 && <div>
 
         <h3>{tickets.length} Tickets to Approve</h3>
           
             <div className="ticketContent-3">
                 
                {//map buckets
                 buckets.map((bucket) => 
                           
                           <div className="ticketBucket">
                                 <div className="ticketBucketHeader">{bucket}
                                {bucket === "investigation" && <span>s&nbsp;&nbsp;<FontAwesomeIcon icon={faMagnifyingGlass}/></span>}
                                {bucket === "issue" && <span>s&nbsp;&nbsp;<FontAwesomeIcon icon={faBug}/></span>}
                                {bucket === "wish" && <span>es&nbsp;&nbsp;<FontAwesomeIcon icon={faWandSparkles}/></span>}
                                </div>
                                     <div className="bucketContent">
                 { // mapping tickets filtered by bucket
                                         tickets.filter((data) => data.type == bucket).map((ticket) =>
                                                     <div className='request-item' key={ticket.id} id={ticket.id} onClick={() => {updateModal(ticket.id)}}>
                                                 <p className={'priorityFlag' + ticket.priority}>P{ticket.priority}</p>
                                                     <span className={'requestType' + ticket.type}>{ticket.type} - {ticket.id} </span>
                                                     
                                                     <div className={"requestHeader" + ticket.type}>
                                                         <b>{ticket.subject}</b>

                                                        {ticket.deleteRequest && <>
                                                        <br></br><br></br>
                                                            <span title="Support want to close this ticket" style={{"cursor":"pointer"}} onClick={()=>{updateModal(ticket.id);deleteRequest(ticket)}}><FontAwesomeIcon style={{"color":"red"}} icon={faTrash} /></span>
                                                        </>}

                                                     </div>
                                                 </div>
                                         )} 
 
                             </div>
                           </div>
                
                )}
                         
 
                 </div>
 
        
              </div>      
         }

        
<div className="approvalSideBar" id="approvalSideBar">
            
    <div className="sidebarHeader">
        <p id="approvalSideBarId">ID</p><p>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</p><p id="approvalSideBarType">type</p>
    </div>

<div className="approvalForm">
            <form onSubmit={submitForm}>
                <input className="" name="id" type="hidden" id="hiddenId"></input>
                <input id = "approvalTime" className="approvalField" name="time" type="number" placeholder="Est. Time" required></input>
                <input id = "approvalComplexity" className="approvalField" name="complexity" type="number" max ={10} min={0} placeholder="Confidence" required></input>
                <input id = "approvalRisk" className="approvalField" name="risk" type="number" max ={10} min={0} placeholder="Risk" required></input>
                <button className="approvalSubmit" type="submit">✔ Confirm</button>
            </form>
        </div>

    <div className="sidebarContent">

        
    

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Description</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarTitle">Placeholder</p></div>
        </div>
                
        <div className="sidebarCard">
            <div className="sidebarCardHeader">Requested By</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarClients">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Product Areas</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarProducts">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Software Area</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarArea">Placeholder</p></div>
        </div>
    

        <div className="sidebarCard">
            <div className="sidebarCardHeader">How to Replicate</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarSteps">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Error</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarError">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Solution / Preferred Outcome</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarPreferredOutcome">Placeholder</p></div>
        </div>
 
        <p id="approvalSideBarCreateText">Approved by name - date</p>
        <p id="approvalSideBarApprovedText">Approved by name - date</p>

 
 
 
        <div className="sidebarPriority" onClick={()=>{
            let x = document.getElementById("priorityMenu").style.display;

            if(x == "block"){
                document.getElementById("priorityMenu").style.display = "none"
            } else {
               document.getElementById("priorityMenu").style.display = "block" 
            }
            }}>
            <p id="approvalSideBarPriority">Priority</p>
        </div>
 
        <div className="priorityChangeMenu" id="priorityMenu">
            <div className="priorityChangeOption" onClick={()=>{updatePriority(1, activeTicket)}}>Priority 1</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(2, activeTicket)}}>Priority 2</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(3, activeTicket)}}>Priority 3</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(4, activeTicket)}}>Priority 4</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(5, activeTicket)}}>Priority 5</div>
        </div>

    </div>

            
    <span className='closeModalCross' onClick={() => {closeModal()}}> X </span>

        </div>

        <div className="deleteRequestContainer" id="deleteRequestContainer">
            <div className="deleteRequestForm">
                <div className="deleteRequestTitle">
                  <b>
                    <span id="deleteName"></span> has tried to close ticket <span id="deleteId"></span>. This was prevented as it has been approved.
                  </b>
                </div>

                <div className="deleteRequestCheckbox">
                <b>Note : A linked Trello ticket will also be deleted once the team member closes the support ticket.</b>
                </div>

                <div className="deleteRequestSubmit">
                  <button className="deleteRequestPermit" onClick={()=>{permitDelete(activeTicket)}}>
                    Permit Closure &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} /></button>
                  <button className="deleteRequestCancel" onClick={()=>{document.getElementById("deleteRequestContainer").style.display = "none"}}>
                    Cancel &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCancel} /></button>
                </div>
            </div>
        </div>

</div>





  );





}

export default Approvals;