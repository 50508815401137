const TimeLine = () => {
    return ( 

        <div>
            <h2>Time Line</h2>
        </div>

     );
}
 
export default TimeLine;