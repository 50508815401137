import { Link } from "react-router-dom";

const MeetingNotes = () => {
    

    
    
    
    return ( 

        <div className="">
            <div className="meetingNewButton"><button onClick={()=>{window.location="Meetings/New"}}>new</button></div>
                
            

        </div>

     );
}
 
export default MeetingNotes;