import { useState, useEffect } from "react";
import Planner from "./planner";
import axios from "axios";
import Cookies from "js-cookie";

function Planning() {

  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  const fetchData = () => {

    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email});
    
    axios.post('getInitPlanner.php', data)
          .then((res) => {
              setResponse(res.data);
              console.log(res.data);
          })
          .catch((err) => {
              setError(err);
          })
          .finally(() => {
              setloading(false);
          });
  };

  useEffect(() => {
      fetchData();
  }, []);





 
  return ( 
    <div className="">

   { error && <div className='error'>{ error }</div>}
    { loading && <div className='loading'><img src="loading.gif" alt="loading" /><br></br><p>Loading...</p></div>}
    { response &&  <div>  {console.log(response)}    
      <Planner trelloMembers={response.trelloMembers} trelloCardsLoading={false} tasks={response.tickets} versions={response.releases} devStatus={response.devStatus} commentHistory={Array()} allTrelloCards={Array()} contextTrelloId={null} />
      </div>}

    </div>

 
 

   );
}
 
export default Planning;