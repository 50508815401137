import { useEffect, useState } from "react";
import axios from 'axios';

const Votes = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);


    const tryAuthenticate = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get("id");
        const secret = queryParameters.get("secret");
        if(secret === "asDEF£fsdsS4423d__DsD@95"){
            //longer term, create a dummy user which will give a JWT and pass JWT through the URL in the ZD comment
            //then create api end point to query the secret which is secured via the JWT validation
            setIsAuthenticated(true);
            setActiveId(id);
        }
    }

    useEffect(() => {
        tryAuthenticate();
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        const url = document.getElementById("newUrl").value
        const data = JSON.stringify({ id: activeId, url: url});

        axios.post("/addVote.php", data)
            .then((res) => {
                console.log(res.data);
                setIsSubmitted(true);
            })
            .catch((err)=>
                alert(err)
            )
        
        
    }

    return ( <div>
        
        {isAuthenticated && !isSubmitted && 
        <div className="addVotes voteForm">
            <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
            <br></br>
           <h2>Add a Vote to #{activeId}</h2>
           <small>Please enter the clients full Osprey URL</small>
           <br></br><br></br>
           <form onSubmit={handleSubmit}>
                <input id="newUrl" type="text" defaultValue={"https://.ospreyapproach.com/main"} required/>
                <br></br><br></br>
                <button type="submit">Submit</button>
           </form>
        </div>}

        {!isAuthenticated && 
        <div className="addVotes noAuth">
            <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
            <br></br>
            <h2>Query parameters not set or not correct. Speak to management.</h2>
        </div>
        }

        {isSubmitted && 
        <div className="addVotes submitted">
            <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
            <br></br>
            <h2>Thank you for providing the information. You can now close this window</h2>
        </div>
        }


    </div> );
}
 
export default Votes;