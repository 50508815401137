import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Font } from "@react-pdf/renderer";
import { faArrowLeft, faArrowRight, faCalendar, faCalendarAlt, faComment, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Preview from "preview-office-docs";

const ViewProject = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)
    const [response, setResponse] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [attachments, setAttachments] = useState(Array());
    const [tickets, setTickets] = useState(Array());
    const [project, setProject] = useState(Array());
    const [specification, setSpecification] = useState(null);
    const navigate = useNavigate();

    const fetchData = () => {

        if(Cookies.get('_auth')){
            setAuthenticated(true);
        }

        const queryParameters = new URLSearchParams(window.location.search);
        const key = queryParameters.get("key");
        const id = queryParameters.get("project");
        const data = JSON.stringify({Key:key, Project:id});

        axios
            .post("getTrelloProject.php", data)
            .then((res) => {
                setLoading(false)
                console.log(res.data)
                if(res.data.error){
                    setError(res.data.error)
                    console.log("apparently there was an error")
                } else {
                    setTickets(res.data.tickets)
                    setProject(res.data.project)
                    setAttachments(res.data.attachments)
                    setSpecification(res.data.project.specification)
                    console.log(res.data)

                
                    

                }
                
            })
            .catch((err)=>{
                alert(err);
            })
            .finally(() => {
                
                console.log(response)
            })
    }       

    useEffect(() => {
        fetchData();
    }, []);

    const docs = [
        { uri: "https://trello.com/1/cards/6551e4aedbf50f765e101cfb/attachments/6551e4aedbf50f765e101d79/download/Sprint_Package.docx", fileType:'docx'},
        { uri: "https://trello.com/1/cards/6551e498f5c63f0840b1398d/attachments/6551e498f5c63f0840b13a0f/download/Outlook_Addin_Mini_Project.docx", fileType:'docx'}  
    ]

    console.log(response)

    return ( <>

      {loading && <>
            <div className="loadingTrelloComments">
                Loading...
            </div>
        </>}

        {error && <>
            <div className="addVotes noAuth">
                <div>
                    <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
                </div><br></br><br></br>
                There was an error<br></br><br></br>{error}
            </div>
        </>}

        {response && !loading && !error && <>

            <div className="trelloCommentsPageContainer">
            
               
                <img src="ospreylogowhite.png" alt="osprey logo" style={{width:"15%", padding:"2vh"}}/>
                <h2>{project.description}</h2>
                
                <div className="projectTicketData"> 
                <h3>Tickets Considered In Project</h3>
                {tickets.map(ticket => <>
                    <div className="ticket">
                        <div className="ticketDataTitle" style={{"color":"#1a8cff"}}>
                            {ticket.subject} <small><i>({ticket.type} {ticket.id})</i></small>            
                        </div>
                        Steps
                        <div className="ticketDataProject">
                            {ticket.steps} 
                        </div>
                        {ticket.type === "issue" ?
                        <>Error
                        <div className="ticketDataProject">
                            {ticket.error} 
                        </div></> : <></>}

                        <hr></hr>
                    </div>
                </>)}
                </div>

                <div className="specificationPreview">
                <h3>Approved Specification</h3>
                    <Preview
                        url={specification}
                        height="700px"
                        width="100%"
                    />
                </div>
            
            
            
            
            
            </div>


            </>}

     

    </> );
}
 
export default ViewProject;