import React, { useState, useEffect } from 'react';
import axios from 'axios';


function FileUpload() {

  const [selectedFile, setSelectedFile] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [currentDocs, setCurrentDocs] = useState(Array());


  const checkIdSet = () => {
      setIsLoading(true)
      const queryParameters = new URLSearchParams(window.location.search);
      const id = queryParameters.get("id");
      const secret = queryParameters.get("secret");
        if(id && secret === "asDEF£fsdsS4423d__DsD@95"){
            setIsAuthenticated(true);
            setActiveId(id);



            const data = JSON.stringify({id:id})
            axios.post("getFilePaths.php", data)
                .then((res => {
                    console.log(res.data)
                    setCurrentDocs(res.data)
                    setIsLoading(false)
                }))
        

           

        }          
      }
  

    useEffect(() => {
        checkIdSet();
    }, []);





  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    setUploaded(false)
    e.preventDefault();
    if (selectedFile) {
      setIsLoading(true)
      
      const formData = new FormData();
      formData.append('file', selectedFile, activeId + "_" + selectedFile.name);
      
      // You can send the formData object to your backend to save the file and get the path
      // For example, using fetch API:
    axios.post("fileUpload.php", formData)
      .then((res=>{
        let path = "https://development.pracctice.com/api/" + res.data.path

        const myData = JSON.stringify({id:activeId, path:path, name:selectedFile.name});

        axios.post("addFilePath.php", myData)
            .catch((err => {
                console.log(err)
            }))
            .finally(()=>{
                e.target.value = null;
                setSelectedFile(null);
            })
      }))
      .catch((err => {
        alert(err);
      }))
      .finally(()=>{
        setUploaded(true);
        checkIdSet();
       
        //alert("file uploaded")
      })


    }
  };


  return (

<>
    {activeId && isAuthenticated &&

      <div className="addVotes voteForm">
        {isLoading && <div className="uploadLoading"><img src="loading.gif" alt="loading" /><br></br>Uploading...</div>}
      <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
      <h2>Upload Files to #{activeId}</h2>
        <form onSubmit={handleSubmit}>
          <input type="file" id="fileToUpload" accept='.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt,.jpg,.png,.gif,.mp3,.wav,.mp4,.mov' onChange={handleFileChange} /> 
          <button type="submit">Upload</button>
        </form> 

        {currentDocs && <div className="currentFiles"> Uploaded Files 
        {currentDocs.map((doc)=>
         <p><a target="_blank" href={doc.path}>{doc.name}</a></p>
        )}
      </div>}

      </div>

    }
    
    
    {uploaded && <div className="addVotes submitted">
            <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
            <br></br>
            <h2>Thank you for providing the file.</h2>
            <h4 onClick={()=>{window.location.reload()}}>Add More</h4>

     {currentDocs && <div className="currentFiles"> Uploaded Files 
        {currentDocs.map((doc)=>
         <p><a target="_blank" href={doc.path}>{doc.name}</a></p>
        )}
      </div>}



        </div>}





      



{!activeId && <div className="fileUploadContainer"><h2>No Ticket Selected or Not Authenticated!</h2></div>}

        </>


  );
}

export default FileUpload;