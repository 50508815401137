import { useNavigate } from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate();

    
    return ( 
<div className="addVotes noAuth">
<img src="ospreylogowhite.png" style={{width:"400px"}}></img>
<br></br><br></br><br></br>
    Oops! You weren't meant to see this page.<br></br>
    <br></br>
    <small>Error 404 - Page was not found</small>
    <br></br><br></br><br></br>
    <span class="clickable button" onClick={()=>{navigate("/")}}>Go Home</span>
    </div>
        
     );
}
 
export default NotFound;