import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, redirect } from 'react-router-dom';
import SetBaseUrl from './Components/baseUrl';
import Navbar from './Components/navbar';
import UserInfoBar from './Components/userInfoBar';
import Home from './Components/home';
import Approvals from './Components/approvals';
import Requests from './Components/requests';
import Planning from './Components/planning';
import Releases from './Components/releases';
import TimeLine from './Components/timeline';
import Users from './Components/users';
import Settings from './Components/settings';
import Parked from './Components/parked';
import NotFound from './Components/notfound';
import MeetingNotes from './Components/meetings';
import NewMeeting from './Components/newMeetings';
import Login from './Components/login';
import { RequireAuth } from 'react-auth-kit';
import Votes from './Components/votes';
import BucketPDFView from './Components/bucketDoc';
import NewCustomItem from './Components/newCustomItem';
import FileUpload from './Components/fileUpload';
import TrelloRefresh from './Components/trelloRefresh';
import ViewDevelopmentNotes from './Components/viewDevelopmentNotes';
import ReleaseData from './Components/releaseData';
import Flagged from './Components/flagged';
import Projects from './Components/projects';
import UiUxProjects from './Components/uiuxProjects';
import ViewProject from './Components/viewProject';



function App() {





  return (
    <div className="app">



      <Router>
        
        
        <div className="content">
          <Routes>
            <Route path="/login" element = {<Login />} />
            <Route path="/" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Home /></RequireAuth>} />  
            <Route path="/Approvals" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Approvals /></RequireAuth>} /> 
            <Route path="/Requests" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Requests /></RequireAuth>} /> 
            <Route path="/Flagged" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Flagged /></RequireAuth>} /> 
            <Route path="/Planning" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Planning /></RequireAuth>} /> 
            <Route path="/Releases" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Releases /></RequireAuth>} />
            <Route path="/ReleaseData" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><ReleaseData /></RequireAuth>} />
            <Route path="/TimeLine" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><TimeLine /></RequireAuth>} />
            <Route path="/Users" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Users /></RequireAuth>} />
            <Route path="/Meetings" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><MeetingNotes /></RequireAuth>} />
            <Route path="/Projects" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Projects /></RequireAuth>} />
            <Route path="/UI-UX" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><UiUxProjects /></RequireAuth>} />
            <Route path="Meetings/New" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><NewMeeting /></RequireAuth>} />
            <Route path="/Settings" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Settings /></RequireAuth>} /> 
            <Route path="/Parked" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><Parked /></RequireAuth>} /> 
            <Route path="/BucketPDF" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><BucketPDFView /></RequireAuth>} /> 
            <Route path="/NewCustomItem" element={<RequireAuth loginPath='/login'><Navbar /><UserInfoBar /><NewCustomItem /></RequireAuth>} />
            <Route path="/TrelloRefresh" element={<RequireAuth loginPath='/login'><Navbar /><TrelloRefresh /></RequireAuth>} />
            <Route path="/FileUpload" element={<FileUpload />} />
            <Route path="/Votes" element={<Votes />} />
            <Route path="/ViewDevelopmentNotes" element={<ViewDevelopmentNotes />} />
            <Route path="/ViewProject" element={<ViewProject />} />
            <Route path="*" element={<NotFound />} />

          </Routes> 
        </div>
      </Router>

 

      <SetBaseUrl />
    </div>
  );
  
}

export default App;
