import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSave, faFilePdf, faTrash, faClose, faCheck, faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { faRProject, faTrello } from '@fortawesome/free-brands-svg-icons';


const Projects = () => {


const [error, setError] = useState("");
const [loading, setLoading] = useState(false);
const [projects, setProjects] = useState(Array());
const [activeTicket, updateActiveTicket] = useState(null);

const [trelloCardsLoading, setTrelloCardsLoading] = useState(false);
const [allTrelloCards, setAllTrelloCards] = useState(Array());
const [activeProject, setActiveProject] = useState(0);


    const fetchData = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('getProjects.php?type=STANDARD', data)
                .then((res => {
                    setProjects(res.data.projects);
                    console.log(res.data);
                }))
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                   // fetchProjects()
                    setLoading(false);
                });

    }

   /* const fetchProjects = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('getProjects.php', data)
                .then((res => {
                    setProjects(res.data.projects);
                    console.log(res.data.projects);
                }))
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    
                    setLoading(false);
                });
    }*/

    useEffect(() => {
        fetchData();
    }, []);


    const showPDF = (id) => {
        alert("print pdf for project id #" + id)

    }


     // api post to update the priority of the ticket
     const updatePriority = (priority, id) => {
  
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {id: id, priority: priority}});
        
        axios.post('updatePriority.php', data)
              .then((res => {
                  if(res.data.Status == "Success"){
                      console.log(res.data.Status)
                      document.getElementById("approvalSideBarPriority").innerHTML = "Priority " + priority;
                      document.getElementById("priorityMenu").style.display = "none";
  
                      fetchData();
                      updateModal(id);
                                      } else {
                      alert(res.data.Response)
                  }
              }))
      }



     //update the sidebar modal on the fly
     const updateModal = (id) => {
        document.getElementById("priorityMenu").style.display = "none"
        updateActiveTicket(id);
        document.getElementById("approvalSideBar").style.display = "block";
       
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {id: id}});
        
        axios.post('ticketData.php', data)
             .then((res => {
                    document.getElementById("approvalSideBarId").innerHTML = res.data.tickets[0].id;
                    document.getElementById("approvalSideBarTitle").innerHTML = res.data.tickets[0].subject;
                    document.getElementById("approvalSideBarType").innerHTML = res.data.tickets[0].type;
                    document.getElementById("approvalSideBarCreateText").innerHTML = res.data.tickets[0].createdText;
                    document.getElementById("approvalSideBarApprovedText").innerHTML = 'Aproved by ' + res.data.tickets[0].approvedText;
                    document.getElementById("approvalSideBarClients").innerHTML = res.data.tickets[0].clients;
                    document.getElementById("approvalSideBarSteps").innerHTML = res.data.tickets[0].steps;
                    document.getElementById("approvalSideBarError").innerHTML = res.data.tickets[0].error;
                    document.getElementById("approvalSideBarPreferredOutcome").innerHTML = res.data.tickets[0].preferredOutcome;
                    document.getElementById("approvalSideBarPriority").innerHTML = 'Priority ' + res.data.tickets[0].priority;


                    let productAreas = res.data.tickets[0].products;
                    productAreas = productAreas.replaceAll(";", "<br>");
                    productAreas = productAreas.replaceAll("__", " > ");
                    productAreas = productAreas.replaceAll("_", " ");

                    document.getElementById("approvalSideBarProducts").innerHTML = productAreas;
                    
                    let areaText = res.data.tickets[0].area;
                    areaText = areaText.replaceAll("__", " > ");
                    areaText = areaText.replaceAll("_", " ");
                    document.getElementById("approvalSideBarArea").innerHTML = areaText;
                }))
                .catch((err => {
                    alert(err);
                }))
        } 



    // X to close the modal
    const closeModal = () => {
        document.getElementById("approvalSideBar").style.display = "none"
    }


    const sendTicketToProject = () => {

    
        if (document.getElementById('allocateTicketProject').value === "None"){
            var $value = 0;
        } else {
            var $value = document.getElementById('allocateTicketProject').value;
        }
            const token = Cookies.get('_auth');
            const email = JSON.parse(Cookies.get('_auth_state')).email;
            const data = JSON.stringify({Token: token, Email: email, Data: {id:activeTicket, project:document.getElementById('allocateTicketProject').value}});
            
            
    
            axios.post('addTicketToProject.php', data)
            .then((res => {
                if(res.data.Status === "Success"){
                
                } else {
                console.log(res.data)
                }
            }))
            .finally(() => {
                document.getElementById("approvalSideBar").style.display="none"
                fetchData()
            })
            
        
      }


      const selectTrelloCard = (project) => {
        setActiveProject(project);
        displayLinkToTrello();
      }

      const displayLinkToTrello = (id) => {
        setTrelloCardsLoading(true)
         const token = Cookies.get('_auth');
         const email = JSON.parse(Cookies.get('_auth_state')).email;
         const data = JSON.stringify({Token: token, Email: email});
       
         axios
           .post("getAllActiveTrelloCards.php", data)
           .then((res) => {
             console.log(res.data);
             setAllTrelloCards(res.data.cards)
             setTrelloCardsLoading(false)
           })
           .catch((err) => {
             console.log(err)
           })
           document.getElementById("linkTicketToTrello").style.display = "block";
       }


       const linkTicketToTrelloCard = (project, trelloId, trelloIdShort) => {

        if(window.confirm("Are you sure you want to link this project to trello card " + trelloIdShort) === true){
            //axios post to update trello id in database
            const token = Cookies.get('_auth');
            const email = JSON.parse(Cookies.get('_auth_state')).email;
            const data = JSON.stringify({Token: token, Email: email, Data:{project:project, trelloId:trelloId}});
        
            axios
              .post("linkProjectToTrello.php", data)
              .then((res) => {
                if(res.data.response === "ok"){
                  displayTrelloAlert(trelloId, activeProject, " linked to ")
                } else {
                  alert(res.data.error)
                }
              })
              .catch((err) => {
                alert(err);
              })
          
        } else {}
        }

        const displayTrelloAlert = (trelloId, ticket, text) => {
  
            //alert(trelloId + " created for - #" + ticket)
            document.getElementById("trelloCreatedId").innerHTML = trelloId;
            document.getElementById("trelloConfirmText").innerHTML = text;
            document.getElementById("linkTicketToTrello").style.display = "none";
            document.getElementById("addTicketToTrello").style.display = "none";
            document.getElementById("trelloCreatedNotification").style.display = "block";
            setTimeout(() => {
              document.getElementById("trelloCreatedNotification").style.display = "none";
            }, 8000)
            fetchData()
          }

    return ( <>
    
    {error && <>There was an error.<br>{error}</br></>}
    {loading && <>Loading...</>}
    {projects && <>
    

        <div id="trelloCreatedNotification">
        Trello Card <span id="trelloCreatedId"></span> was <span id="trelloConfirmText"></span> #{activeTicket} <FontAwesomeIcon icon={faCheck} />
      </div>

    <h4><br></br>Projects</h4>
    <div className="ticketContent-unknown">
        {projects.map(project =>

<div className="ticketBucket" key={project.id}>
    <div className="ticketBucketHeader">
        {project.description}<br></br><br></br>
        {project.tickets.length > 0 && <>
            <div className="pdfButton" onClick={()=> window.location.assign("/BucketPDF?bucket=" + project.id + "&type=project")}><FontAwesomeIcon icon={faFilePdf} /></div>

            {project.trelloId === null ?
            <><FontAwesomeIcon onClick={()=>{selectTrelloCard(project.id)}} icon={faTrello} /></>
            :
            <>
            <br></br>
            <a href={"/ViewProject?project=" + project.id + "&key=DCBbXrt7WiScKvHIlmCVUXJUHKWhNQGgeqoejRNSlnM"} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faDiagramProject} />
                Project Overview
            </a>
            </>}

        </>}
        <h4 style={{color:"#fff"}}><br></br>{project.tickets.length} Tickets</h4> 
    </div>
    <div className="bucketContent">
        &nbsp;
    {project.tickets.map(ticket => 
        <>
         <div className='request-item' key={ticket.id} id={ticket.id} onClick={() => {updateModal(ticket.id)}}>
            <p className={'priorityFlag' + ticket.priority}>P{ticket.priority}</p>
                <span className={'requestType' + ticket.type}>{ticket.type} - {ticket.id} </span>
                
                <div className={"requestHeader" + ticket.type}>
                    
                    <b>{ticket.description}</b>

                {ticket.deleteRequest && <>
                        <p className='deleteNotifier'><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;&nbsp;{ticket.deleteRequest}'s request to close the ticket was approved.</p>
                    </>}
                
                </div>
            </div>
        </>
        )}
    </div>
</div>



        )}

        </div>
    
    </>}
    
    <div className="approvalSideBar" id="approvalSideBar">
            
    <div className="sidebarHeader">
        <p id="approvalSideBarId">ID</p><p>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</p><p id="approvalSideBarType">type</p>
    </div>

    <div className="sidebarContent">

    <div className="sideBarCard">
    <div className="sidebarCardHeader">Allocate to Project</div>
    <div className="sidebarCardInfo">
        
        <select id="allocateTicketProject">
                <option value="None">No Allocation</option>
            {projects.map(project => 
                <option key={project.id} value={project.id}>{project.description} - {(project.type.toLowerCase())} </option>
            )}        
        </select>
        <button onClick={()=>{sendTicketToProject()}}><FontAwesomeIcon className="" icon={faSave} /></button>
    </div>
</div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Description</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarTitle">Placeholder</p></div>
        </div>
                
        <div className="sidebarCard">
            <div className="sidebarCardHeader">Requested By</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarClients">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Product Areas</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarProducts">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Software Area</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarArea">Placeholder</p></div>
        </div>
    

        <div className="sidebarCard">
            <div className="sidebarCardHeader">How to Replicate</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarSteps">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Error</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarError">Placeholder</p></div>
        </div>

        <div className="sidebarCard">
            <div className="sidebarCardHeader">Solution / Preferred Outcome</div>
            <div className="sidebarCardInfo"><p id="approvalSideBarPreferredOutcome">Placeholder</p></div>
        </div>
 
        <p id="approvalSideBarCreateText">Approved by name - date</p>
        <p id="approvalSideBarApprovedText">Approved by name - date</p>

 
 
 
        <div className="sidebarPriority" onClick={()=>{
            let x = document.getElementById("priorityMenu").style.display;

            if(x == "block"){
                document.getElementById("priorityMenu").style.display = "none"
            } else {
               document.getElementById("priorityMenu").style.display = "block" 
            }
            }}>
            <p id="approvalSideBarPriority">Priority</p>
        </div>
 
        <div className="priorityChangeMenu" id="priorityMenu">
            <div className="priorityChangeOption" onClick={()=>{updatePriority(1, activeTicket)}}>Priority 1</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(2, activeTicket)}}>Priority 2</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(3, activeTicket)}}>Priority 3</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(4, activeTicket)}}>Priority 4</div>
            <div className="priorityChangeOption" onClick={()=>{updatePriority(5, activeTicket)}}>Priority 5</div>
        </div>

    </div>

            
    <span className='closeModalCross' onClick={() => {closeModal()}}> X </span>
</div>



<div className="linkToTrelloContainer" id="linkTicketToTrello">
            <div className="linkToTrelloForm">
              <span className="closeTrelloAdd" onClick={()=>{document.getElementById("linkTicketToTrello").style.display = "none"}}><FontAwesomeIcon icon={faClose} /></span>
              <h1>Link Ticket {activeProject} to Existing Trello Card <FontAwesomeIcon style={{"color":"#3D517B"}} icon={faTrello}/></h1>    
              
              {trelloCardsLoading && <>
                <img src="loading.gif" />
                <h3>Loading Trello Cards...</h3>
              </>}
              
              {!trelloCardsLoading && allTrelloCards
                .sort((a,b) => a.idShort < b.idShort ? 1 : -1)
                .map((card) => 
                  (
                    <div className="linkTrelloCard" onClick={()=>{linkTicketToTrelloCard(activeProject, card.id, card.idShort)}}>
                      <b>{card.idShort}</b> - {card.name}
                    </div>
                  )
                )}
            </div>
        </div>


    </> );
}
 
export default Projects;