import axios from "axios";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";


const Settings = () => {

const [loading, setLoading] = useState(true);
const [error, setError] = useState([]);
const [data, setData] = useState([]);

    const fetchData = () => {
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});
          axios.post('getSettings.php', data)
                .then((res => {
                    setData(res.data);
                }))
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });

    }
   
    useEffect(() => {
        fetchData();
    }, []);

    return ( 
        <div>  
        {loading && <>
        
        </>}
        {error && <>
        
        </>}
        {data && <>
        
            




        </>}
        </div>
     );
}
 
export default Settings;