import axios from 'axios'
import Cookies from "js-cookie";
import { useEffect, useState } from "react";




const TrelloRefresh = () => {

    const [loadingTickets, setLoadingTickets] = useState(true);
    const [tickets, setTickets] = useState(Array());
    const [errorLoadingTickets, setErrorLoadingTickets] = useState(null);
    const [syncStart, setSyncStart] = useState(false);
    const [currentlyUpdating, setCurrentlyUpdating] = useState('');
    const [updateComplete, setUpdateComplete] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [mailSentResponse, setMailSentResponse] = useState("");
    const [syncComplete, setSyncComplete] = useState(false);
    const [updatedTickets, setUpdatedTickets] = useState(Array());
    /*const [releases, setReleases] = useState(Array());
    const [activeRelease, setActiveRelease] = useState(null);
    const [activeReleaseName, setActiveReleaseName] = useState(null);*/


//intial fetch of data
const fetchData = () => {
    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email});

    axios
        .post("getTrelloRefreshData.php", data)
        .then((res => {
            setTickets(res.data.ticketsToUpdate)
            console.log(res.data)
        }))
        .catch((err => {
            setErrorLoadingTickets(err)
        }))
        .finally(() => {
            setLoadingTickets(false)
        })
}

useEffect(() => {
    fetchData();
}, []);

useEffect(()=> {
    if(syncComplete === true){
        setUpdateComplete(true)
    }
}, syncComplete)


const loopTickets = () => {
    setSyncStart(true);
    tickets.map(ticket => {
        trelloRefresh(ticket.plannerId, ticket.trelloId)
    })
    setSyncComplete(true);
}


const trelloRefresh = (plannerId, trelloId) => {

    console.log("updating " + plannerId)
    setCurrentlyUpdating(plannerId)

    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email, Data:{plannerId: plannerId, trelloId: trelloId}});

    axios
        .post("refreshFromTrello.php", data)
        .then((res => {
            console.log(res.data)
            setUpdatedTickets(updatedTickets => [... updatedTickets, res.data])
        }))
        .catch((err => {
            console.log(err) 
        }))
        .finally(() => {
            
        })

}


const createUpdate = () => {
    setMailSent(true);
    console.log("************* STOPS HERE *************")
    console.log(updatedTickets);

    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    //const useTheseTickets = JSON.stringify(updatedTickets)
    const data = JSON.stringify({Token: token, Email: email, Data: updatedTickets});

    axios
        .post("trelloRefreshBatchUpdate.php", data)
        .then((res)=> {
            setMailSentResponse(res.data)
        })
        .catch((err) => {
            alert(err)
        })

}



    return ( <>

    <div className="title">Sync Tickets with Trello Data</div>
    
        {loadingTickets && <>
            <div className="initalLoad">Downloading Tickets</div>
        </>}

        {!loadingTickets && !syncStart && <>
            <div onClick={()=>{loopTickets()}}>
                <button className="syncStart">
                    Click to Start
                </button>
            </div>
        </>}

        {!loadingTickets && syncStart &&  <>

        {currentlyUpdating && !syncComplete && <>
            <div className="currentTicketUpdating">
                Currently Checking Ticket: {currentlyUpdating}
            </div>        
        </>}

        {syncComplete && !mailSent && <>
        <div className='updateComplete' onClick={createUpdate}>Updating Complete - Click to Send Email</div>
        </>}

        {mailSent && <>
        <div className="mailSent">Email Sent :&nbsp;
        {mailSentResponse.mailSent === true ? "Yes" : ""}
        {mailSentResponse.mailSent === false ? "No, there was an error" : ""}
        {mailSentResponse.mailSent !== true && mailSentResponse.mailSent !== false ? mailSentResponse.mailSent : ""}
        {!mailSentResponse ? "Calculating..." : ""}
        </div>
        </>}

           <b><h4>Tickets Updated:</h4></b>
            
            <br></br>
            {updatedTickets.map((ticket)=> (
                <>
                {ticket.statusUpdate !== "No Status Update Necessary" && <>
                <div className="updateCard" key={ticket.ticketId}>
                <div className="updateHeader">
                {ticket.ticketId} - {ticket.ticketDescription}
                </div>
                {ticket.error && <div className="updateError">{ticket.error}</div>}
                {ticket.statusUpdate && <div className='updateStatus'>{ticket.statusUpdate}</div>}
                </div>               
                </>}              
                </>
            ))}

        </>}

    </> );
}
 
export default TrelloRefresh;