import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

const UserInfoBar = () => {
    //set functions for logout button
    const signOut = useSignOut();
    const navigate = useNavigate();
    
    //logout button functions
    const logout = () => {
        signOut();
        navigate("/login");
    }

    //intilaise states
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(Array());
    const [usersName, setUsersName] = useState("");
    const [activeStats, setActiveStats] = useState("");

    //initial fetch of data
    const fetchData = (choice) => {
        setLoading(true);
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email});

        axios.post('getTopBar.php',data)
            .then((res)=>{
                setResponse(res.data[choice]);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(()=>{
                setUsersName(JSON.parse(Cookies.get('_auth_state')).name);
                setLoading(false);
                setActiveStats(choice);
            })
    }

    useEffect(() => {
        fetchData("Bucket")
    }, [])



const nextStat = () => {
    document.getElementById("stats").style.display = "none";

    if (activeStats == "Bucket"){
        refreshData("ReviewStatus");
        document.getElementById("stats").style.display = "flex";
        return;
    }
    if (activeStats == "ReviewStatus"){
        refreshData("Type");
        document.getElementById("stats").style.display = "flex";
        return;
    }
    if (activeStats == "Type"){
        refreshData("Priority");
        document.getElementById("stats").style.display = "flex";
        return;
    }
    if (activeStats == "Priority"){
        refreshData("CompletionStatus");
        document.getElementById("stats").style.display = "flex";
        return;
    }
    if (activeStats == "CompletionStatus"){
        refreshData("Bucket");
        document.getElementById("stats").style.display = "flex";
        return;
    }
}

const refreshData = (choice) => {
    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email});

    axios.post('getTopBar.php',data)
        .then((res)=>{
            setResponse(res.data[choice]);
        })
        .catch((err) => {
            setError(err);
        })
        .finally(()=>{
            setActiveStats(choice);
        })
}


    return ( 

        <div className="userInfo">
            <div className="userInfoItem userInfoLogOut">
                <button onClick={logout}>Log Out&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faRightFromBracket} /></button>
            </div>
            { loading && <div className="userInfoLoading">Loading</div> }
            { error && <div className="userInfoError">{error}</div> }
            { response && <div className="userInfoResponse">
                <div className="userInfoItem userName">
                    Welcome, {usersName}
                </div>
                
        <div className="userInfoItem userInfoItemNext" onClick={()=>{nextStat()}}>Stats by {activeStats} &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></div>
        <div id="stats" className="userInfoItem userInfoItemStats">
    {response.map((stat) =>
        <p className="userInfoStat">{stat.Name} - <b><u>{stat.Count}</u></b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    )}
        </div>
</div>}
         

        </div>

        

     );
}
 
export default UserInfoBar;