import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faClock, faClose, faComments, faCross, faExplosion, faFaceSmileBeam, faFilePdf, faLink, faMattressPillow, faSadCry, faSadTear, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Docx from 'docx';
import { renderAsyncDocument } from 'react-docx';
//import { Paragraph, Document, Packer, Page, TextRun, Table } from "docx";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import fileDownload from 'js-file-download';
import JSZip from "jszip";
import DocumentCreator from "./bucketDoc";
import { useState } from "react";
 
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { Navigate } from "react-router-dom";
import { faTrello } from "@fortawesome/free-brands-svg-icons";
class Planner extends React.Component {

//define states
  state = { tasks: [],
            commentHistory: [],
            versions: [],
            devStatus: [],
            contextTrelloId: "",
            activeTicket: null,
            generatedDoc: null,
            allTrelloCards: [],
            trelloCardsLoading: false,
            trelloMembers: [] }; 


//update state values from props passed in planning.js 
  componentDidMount() {
    //allTrelloCards

    const { trelloMembers } = this.props;
    this.setState({
      trelloMembers
    });


    const { trelloCardsLoading } = this.props;
    this.setState({
      trelloCardsLoading
    });

    const { allTrelloCards } = this.props;
    this.setState({
      allTrelloCards
    });

    const { tasks } = this.props;
    this.setState({
      tasks
    });
    const { versions } = this.props;
    this.setState({
        versions
    });
    const { devStatus } = this.props;
    this.setState({
       devStatus
    });
    const { activeTicket } = this.props;
    this.setState({
      activeTicket
    });
    const { generatedDoc } = this.props;
    this.setState({
      generatedDoc
    });
    const { contextTrelloId } = this.props;
    this.setState({
      contextTrelloId
    });
    // this.setState({contextTrelloId: id});
  }


  //handle drag and drop events
  onDragStart = (evt) => {
    document.getElementById("contextMenu").style.display = "none"
    let element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
  };
  onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };
  onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };
  onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };
  onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };
  onDrop = (evt, value, bucket) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    let data = evt.dataTransfer.getData("text/plain");
    let tasks = this.state.tasks;
    let updated = tasks.map((task) => {
      if (task.id.toString() === data.toString()) {
        task.bucket = bucket;
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {id: task.id, bucket: bucket}});
        axios.post('updateBucket.php', data)
        .catch((err) => alert(err))

        const newComment = "This request has been moved into " + bucket + " bucket";
        const user = JSON.parse(Cookies.get('_auth_state')).name;
        const commentData = JSON.stringify({Token: token, Email: email, Data: {user:user, comment:newComment, id: task.id, type:"Event"}});
        axios.post('createComment.php', commentData)
          .catch((err => {alert(err)}))
      }     
       return task;
    });
    this.setState({ tasks: updated });
  };

  render() {

    const { tasks, commentHistory, versions, activeTicket, allTrelloCards, trelloCardsLoading, trelloMembers } = this.state;
    const UpdateTickets = () => {
      const token = Cookies.get('_auth');
      const email = JSON.parse(Cookies.get('_auth_state')).email;
      const data = JSON.stringify({Token: token, Email: email});
      
      axios.post('getInitPlanner.php', data)
      .then((res => {
        this.setState({tasks: res.data.tickets});
        /*this.setState({devStatus: res.data.devStatus})*/
         /* setLoading(false);
          setTickets(res.data.tickets);*/
      }))
      .catch((err) => {
          /*setError(err);*/
      })
      .finally(() => {
          /*setLoading(false);*/
      });
      
   }
    


    //define unplanned array
    let unplanned = tasks.filter((data) => data.bucket == "Unplanned");
    
    //update active ticket state
    const setActiveTicket = (id) =>{
      this.setState({activeTicket: id});
      console.log("active ticket set to " + id)
    }

    const setContextTrelloId = (trelloId) => {
      this.setState({contextTrelloId: trelloId});
    }


    //open comment history and update for active Ticket
    const openCommentHistory = () => {
      const token = Cookies.get('_auth');
      const email = JSON.parse(Cookies.get('_auth_state')).email;
      const data = JSON.stringify({Token: token, Email: email, Data: {id: activeTicket}});
      
      axios.post('getTicketComments.php', data)
          .then((res => {
            document.getElementById("commentHistory").style.display = "block";
            document.getElementById("commentHistoryTicketId").innerHTML = activeTicket + " Comment History";
            console.log(res.data)
            this.setState({commentHistory : res.data})
            document.getElementById("contextMenu").style.display = "none"
            }))
      }


    // handle Form submit for new comment for logged in user ****/// logged in user not yet defined (manual pass to session storage atm!)
    const handleCommentSubmit = () => {
      const newComment = document.getElementById("commentTextArea").value;

      if(newComment === ""){
        alert("You must enter a message")
      } else {
        const user = JSON.parse(Cookies.get('_auth_state')).name;
        const token = Cookies.get('_auth');
        const email = JSON.parse(Cookies.get('_auth_state')).email;
        const data = JSON.stringify({Token: token, Email: email, Data: {user:user, comment:newComment, id: activeTicket, type:"Manual"}});
        
        axios.post('createComment.php', data)
          .then((res => {
            console.log(res.data.Status)
            UpdateTickets()
            openCommentHistory()
            document.getElementById("commentTextArea").value = ""
          }))
          .catch((err => {
            alert(err)
          }))
        }
    }
  

    // update the side bar modal with the selected ticket id values

    const updateModal = (id) => {
      document.getElementById("commentHistory").style.display = "none";
      document.getElementById("contextMenu").style.display = "none";

      const token = Cookies.get('_auth');
      const email = JSON.parse(Cookies.get('_auth_state')).email;
      const data = JSON.stringify({Token: token, Email: email, Data: {id: id}});
      setActiveTicket(id);

      axios.post('ticketData.php', data)
                .then((res => {
                    if(res.data.tickets[0] != null){
                    document.getElementById("approvalSideBarId").innerHTML = res.data.tickets[0].id;
                    document.getElementById("approvalSideBarTitle").innerHTML = res.data.tickets[0].subject;
                    document.getElementById("approvalSideBarType").innerHTML = res.data.tickets[0].type;
                    document.getElementById("approvalSideBarCreateText").innerHTML = res.data.tickets[0].createdText;
                    document.getElementById("approvalSideBarApprovedText").innerHTML = res.data.tickets[0].approvedText;
                    document.getElementById("approvalSideBarClients").innerHTML = res.data.tickets[0].clients;
                    document.getElementById("approvalSideBarSteps").innerHTML = res.data.tickets[0].steps;
                    document.getElementById("approvalSideBarError").innerHTML = res.data.tickets[0].error;
                    document.getElementById("approvalSideBarPreferredOutcome").innerHTML = res.data.tickets[0].preferredOutcome;

                    document.getElementById("approvalSideBarPriority").innerHTML = "Priority " + res.data.tickets[0].priority;

                    let productAreas = res.data.tickets[0].products;
                    productAreas = productAreas.replaceAll(";", "<br>");
                    productAreas = productAreas.replaceAll("__", " > ");
                    productAreas = productAreas.replaceAll("_", " ");

                    document.getElementById("approvalSideBarProducts").innerHTML = productAreas;

                     let areaText = res.data.tickets[0].area;
                     areaText = areaText.replaceAll("__", " > ");
                     areaText = areaText.replaceAll("_", " ");
                     document.getElementById("approvalSideBarArea").innerHTML = areaText;


                    document.getElementById("approvalSideBar").style.display = "block";
                    } else {
                        alert("ERROR: ticket ID not found or there was an issue retrieving the data!")
                    }

                }))
            clearTimeout(this.clickTimeout)
              this.clickTimeout = null
              }

   

    // close modal, currently enforced with X icon.

    const closeModal = (id) => {
        document.getElementById(id).style.display = "none"
    }


    
    //get hours for given bucket description and return the value
    const getVersionHours = (desc) => {
      var arrTemp = tasks;
      arrTemp = arrTemp.filter((data) => data.bucket == desc);
      return(arrTemp.reduce((a,v) => a = a + Number(v.time),0))
    }


    // Clickable username within the comments form to input the username tag into the current textarea value
    const inputUsername = (user) => {
      let val = document.getElementById("commentTextArea").value;
      user = user.replaceAll(" ", "_")
      document.getElementById("commentTextArea").value = val + " @" + user + " ";
      document.getElementById("commentTextArea").focus()
    }

    // right click option on the items to update the status and show the comments popup
    const showContextMenu = (id, trelloId, e) => {
      var z = document.getElementById("contextMenu").style.display;
      setContextTrelloId(trelloId);
      if(z == "none"){
      document.getElementById("contextHeader").innerHTML = "Update Status - " + id
      document.getElementById("approvalSideBar").style.display = "none";
      document.getElementById("commentHistory").style.display = "none";
      setActiveTicket(id);
      //console.log(id, e.pageX)
      document.getElementById("contextMenu").style.top = (e.pageY - 50) + "px"
      document.getElementById("contextMenu").style.left = (e.pageX-200) + "px"
      document.getElementById("contextMenu").style.display = "block"
      } else {
        document.getElementById("contextMenu").style.display = "none"
      }
    }


    //handle dev status updates from the context menu
    const updateTicketState = (state, description, id) => {

      const token = Cookies.get('_auth');
      const email = JSON.parse(Cookies.get('_auth_state')).email;
      const data = JSON.stringify({Token: token, Email: email, Data: {id: id, status: state}});
      axios.post('updateDevStatus.php', data)
        .then((res=>{
            let msg = "Development Status Update: " + description;
            addComment(id, msg, description)          
            UpdateTickets()
            document.getElementById("contextMenu").style.display = "none";
        }))
        .catch((err)=>{
          alert("Error - " + err)
        })
    }
    

  // create comments from events
  const addComment = (id, msg, description) => {
      const token = Cookies.get('_auth');
      const email = JSON.parse(Cookies.get('_auth_state')).email;
      const user = JSON.parse(Cookies.get('_auth_state')).name;
      const commentData = JSON.stringify({Token: token, Email: email, Data: {user:user, comment:msg, id: id, type:"Event"}});
      axios.post('createComment.php', commentData)
        .catch((err => {alert(err)}))
  }
    
    
    
    
  // api post to update the priority of the ticket
  const updatePriority = (priority, id) => {
  
    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email, Data: {id: id, priority: priority}});
    
    axios.post('updatePriority.php', data)
          .then((res => {
              if(res.data.Status == "Success"){
                  console.log(res.data.Status)
                  document.getElementById("approvalSideBarPriority").innerHTML = "Priority " + priority;
                  document.getElementById("priorityMenu").style.display = "none";
                  UpdateTickets();
                                  } else {
                  alert(res.data.Response)
              }
          }))
  }



//nav to PDF viewer passing bucket in the query params
const showPDF = (bucket) => {
  window.location.assign("/BucketPDF?bucket=" + bucket + "&type=bucket")
}


const handleNewItemSubmit = (e) => {
  e.preventDefault();

}



const addToTrello = (id) => {
  const token = Cookies.get('_auth');
  const email = JSON.parse(Cookies.get('_auth_state')).email;
  const data = JSON.stringify({Token: token, Email: email, Data: {id: id}});

  axios.post('ticketData.php', data)
            .then((res => {
              console.log(res.data.tickets);
              let ticket = res.data.tickets[0];

                    let productAreas = ticket.products;
                    productAreas = productAreas.replaceAll(";", "\n");
                    productAreas = productAreas.replaceAll("__", " > ");
                    productAreas = productAreas.replaceAll("_", " ");

                    let clients = ticket.clients;
                    clients = clients.replaceAll(";", "\n");

                     let areaText = ticket.area;
                     areaText = areaText.replaceAll("__", " > ");
                     areaText = areaText.replaceAll("_", " ");
                     document.getElementById("approvalSideBarArea").innerHTML = areaText;


              document.getElementById("addTrelloDescription").value = ticket.subject + " - #" + id;
              document.getElementById("addTrelloContent").value =  "**TYPE** : " + ticket.type + "\n\n"
              document.getElementById("addTrelloContent").value += "**CLIENTS**\n" + clients + "\n\n"
              document.getElementById("addTrelloContent").value += "**PRODUCTS AFFECTED**\n" + productAreas + "\n\n"
              document.getElementById("addTrelloContent").value += "**AREA**\n" + areaText + "\n\n"
              document.getElementById("addTrelloContent").value += "**STEPS TO REPRODUCE**\n" + ticket.steps + "\n\n"
              document.getElementById("addTrelloContent").value += "**ERROR**\n" + ticket.error + "\n\n"
              document.getElementById("addTrelloContent").value += "**PREFERRED OUTCOME**\n" + ticket.preferredOutcome + "\n\n"
            }))

document.getElementById("contextMenu").style.display = "none";
document.getElementById("addTicketToTrello").style.display = "block";
}



const newTrelloTicket = (e) => {
  e.preventDefault();

  let title = document.getElementById("addTrelloDescription").value;
  let content = document.getElementById("addTrelloContent").value;
  let priority = document.getElementById("priorityFlag").value;
  let assignee = document.getElementById("assignee").value;

  const token = Cookies.get('_auth');
  const email = JSON.parse(Cookies.get('_auth_state')).email;
  const data = JSON.stringify({Token: token, Email: email, Data: {ticket:activeTicket, title: title, content: content, priority:priority, assignee:assignee}});


  axios.post('createNewTrelloTicket.php', data)
  .then((res => {
    if(res.data.id){
      displayTrelloAlert(res.data.idShort, activeTicket, " created for ")
    } else {
      alert(res.data)
    }
  }))
  /*alert("submitted", data);*/
}

const displayTrelloAlert = (trelloId, ticket, text) => {
  
  //alert(trelloId + " created for - #" + ticket)
  document.getElementById("trelloCreatedId").innerHTML = trelloId;
  document.getElementById("trelloConfirmText").innerHTML = text;
  document.getElementById("linkTicketToTrello").style.display = "none";
  document.getElementById("addTicketToTrello").style.display = "none";
  document.getElementById("trelloCreatedNotification").style.display = "block";
  setTimeout(() => {
    document.getElementById("trelloCreatedNotification").style.display = "none";
  }, 8000)
  UpdateTickets()
}




const displayLinkToTrello = (id) => {
 this.setState({trelloCardsLoading:true})
  const token = Cookies.get('_auth');
  const email = JSON.parse(Cookies.get('_auth_state')).email;
  const data = JSON.stringify({Token: token, Email: email});

  axios
    .post("getAllActiveTrelloCards.php", data)
    .then((res) => {
      console.log(res.data);
      this.setState({allTrelloCards: res.data.cards})
      this.setState({trelloCardsLoading:false})
    })
    .catch((err) => {
      console.log(err)
    })
    document.getElementById("contextMenu").style.display = "none";
    document.getElementById("linkTicketToTrello").style.display = "block";
}



const linkTicketToTrelloCard = (ticket, trelloId, trelloIdShort) => {

if(window.confirm("Are you sure you want to link " + ticket + " to trello card " + trelloIdShort) === true){
    //axios post to update trello id in database
    const token = Cookies.get('_auth');
    const email = JSON.parse(Cookies.get('_auth_state')).email;
    const data = JSON.stringify({Token: token, Email: email, Data:{ticket:ticket, trelloId:trelloId}});

    axios
      .post("linkCardToTrello.php", data)
      .then((res) => {
        if(res.data.response === "ok"){
          displayTrelloAlert(trelloId, ticket, " linked to ")
        } else {
          alert(res.data.error)
        }
      })
      .catch((err) => {
        alert(err);
      })
  
} else {}
}


const deleteRequest = (ticket) => {
  document.getElementById("deleteName").innerHTML = ticket.deleteRequest;
  document.getElementById("deleteId").innerHTML = ticket.id;
  document.getElementById("deleteRequestContainer").style.display = "block";
}


const permitDelete = (id) => {
  const token = Cookies.get('_auth');
  const email = JSON.parse(Cookies.get('_auth_state')).email;
  const data = JSON.stringify({Token: token, Email: email, Data:{ticket:id}});

  axios
    .post("permitDeletion.php", data)
    .then((res) => 
      {if(res.data.update === "SQL Ok. Zendesk Ok."){console.log("success")} else {console.log("error")}}
    )
    .catch((err) => {
      alert(err);
    })
    .finally(()=>{
      document.getElementById("deleteRequestContainer").style.display = "none";
      UpdateTickets();
    })
}


const wrongBoardCheck = (id) => {
  alert("checking if " + id + " is in the correct board!")

  
}


    return (
        
<div className="">

      <div id="trelloCreatedNotification">
        Trello Card <span id="trelloCreatedId"></span> was <span id="trelloConfirmText"></span> #{activeTicket} <FontAwesomeIcon icon={faCheck} />
      </div>


      <div className="container">
        <div
          className="unplanned small-box"
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragEnd={(e) => this.onDragEnd(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e, false, "Unplanned")}
        >
          <section className="drag_container">
          
              <div className="drag_column"> 
              <div className="columnHeader">
       <div className="pdfButton" onClick={()=> showPDF("Unplanned")}><FontAwesomeIcon icon={faFilePdf} /></div>          
<h4>UnPlanned - <small>{unplanned.reduce((a,v) => a = a + Number(v.time) ,0)} Hours<br></br><br></br><br></br></small>
<br></br></h4>
             

              </div>


                <div className="drag_row">
                 
                  
                  {unplanned.map((task) => (
                  
                    <div
                      className={'card ' + task.status}
                      key={task.id}
                      id={task.id}
                      draggable
                      onDragStart={(e) => this.onDragStart(e)}
                      onDragEnd={(e) => this.onDragEnd(e)}
                      onClick={()=>{updateModal(task.id)}}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        showContextMenu(task.id, task.trelloId, e)
                      }}
                    >   
                    <div className={"plannerPriority" + task.priority}>{task.priority}</div>
                    <div className={"plannerType" + task.type.replaceAll(" ", "")}>{task.type} - {task.id}</div>
                    <div className="plannerDescription">{task.subject}</div>
                    {task.trelloId !== "" && <>
                      <div className="plannerStats">
                        <span onClick={()=>{}}><FontAwesomeIcon icon={faTrello}/></span>&nbsp;&nbsp;&nbsp;
                        </div>
                    </>}
                    {task.deleteRequest && <>
                        <span title="Support want to close this ticket" style={{"cursor":"pointer"}} onClick={()=>{updateModal(task.id);deleteRequest(task)}}><FontAwesomeIcon style={{"color":"red"}} icon={faTrash} /></span>
                        </>}
                    
                    {task.devStatus !== null && <div className = "plannerStatus" style={{"backgroundColor":task.devStatusColour}}>{task.devStatus}</div>}
                    </div>
                  ))}
                  
                       
                  
                </div>
            
            </div>
          </section>
        </div>


{versions.map((thisVersion) => (
    
        <div
          className="version small-box"
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragEnd={(e) => this.onDragEnd(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e, false, thisVersion.description)}
        >
          <section className="drag_container">
            
              <div className="drag_column"> 
               <div className="columnHeader">
               <div className="pdfButton" onClick={()=> showPDF(thisVersion.description)}><FontAwesomeIcon icon={faFilePdf} /></div> 
               <h4>{thisVersion.description}<br></br><small>{getVersionHours(thisVersion.description)} / {thisVersion.devHours} Hours<br></br>{new Date(thisVersion.end).toLocaleDateString("en-UK")}</small></h4></div>
                <div className="drag_row">
                  
                  {tasks.filter((data) => data.bucket === thisVersion.description).length !== 0 &&
                  tasks.filter((data) => data.bucket === thisVersion.description).map((task) => (
        
                        <div
                        className={'card ' + task.status}
                        key={task.id}
                        id={task.id}
                        draggable
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnd={(e) => this.onDragEnd(e)}
                        onClick={()=>{updateModal(task.id)}}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          showContextMenu(task.id, task.trelloId, e)
                        }}
                    >   
                    
                    <div className={"plannerPriority" + task.priority}>{task.priority}</div>
                    <div className={"plannerType" + task.type.replaceAll(" ", "")}>{task.type} - {task.id}</div>
                    
                    <div className="plannerDescription">{task.subject}</div>                  
                    <div className="plannerStats">
                    {task.trelloId !== "" && <>
                      <span title={task.trelloId}  className="trelloIcon"><FontAwesomeIcon icon={faTrello}/></span>
                      {Number(task.devBoard) === 0 && <><br></br><span onClick={()=>{wrongBoardCheck(task.id)}} className="TrelloWrongBoard"> <FontAwesomeIcon icon={faSadCry} />&nbsp;&nbsp;&nbsp;Hmm... Wrong Board?</span></>}
                    </>}
                    {task.deleteRequest && <>
                        <span title="Support want to close this ticket" style={{"cursor":"pointer"}} onClick={()=>{updateModal(task.id);deleteRequest(task)}}><FontAwesomeIcon style={{"color":"red"}} icon={faTrash} /></span>
                        </>}
                    {task.commentCount > 0 && <span className="commentCount">|&nbsp;&nbsp;{task.commentCount} &nbsp;<FontAwesomeIcon icon={faComments} />&nbsp;</span>}
                    </div>
                    {task.devStatus !== null && <div className = "plannerStatus" style={{"backgroundColor":task.devStatusColour}}>{task.devStatus}</div>}
                    
                    </div>
                  ))}
                </div>
              
            </div>
          </section>
        </div>




))}
    </div>
      

    <div className="approvalSideBar" id="approvalSideBar">
            
            <div className="sidebarHeader">
                <p id="approvalSideBarId">ID</p><p>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</p><p id="approvalSideBarType">type</p>
            </div>
        
            <div className="sidebarContent">
        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Description</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarTitle">Placeholder</p></div>
                </div>
                        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Requested By</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarClients">Placeholder</p></div>
                </div>

                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Product Areas</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarProducts">Placeholder</p></div>
                </div>
        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Software Area</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarArea">Placeholder</p></div>
                </div>
            
        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">How to Replicate</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarSteps">Placeholder</p></div>
                </div>
        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Error</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarError">Placeholder</p></div>
                </div>
        
                <div className="sidebarCard">
                    <div className="sidebarCardHeader">Solution / Preferred Outcome</div>
                    <div className="sidebarCardInfo"><p id="approvalSideBarPreferredOutcome">Placeholder</p></div>
                </div>
         
                <p id="approvalSideBarCreateText">Approved by name - date</p>
                <p id="approvalSideBarApprovedText">Approved by name - date</p>
        
         
         
         
                <div className="sidebarPriority" onClick={()=>{
                    let x = document.getElementById("priorityMenu").style.display;
        
                    if(x === "block"){
                        document.getElementById("priorityMenu").style.display = "none"
                    } else {
                       document.getElementById("priorityMenu").style.display = "block" 
                    }
                    }}>
                    <p id="approvalSideBarPriority">Priority</p>
                </div>
         
                <div className="priorityChangeMenu" id="priorityMenu">



                    <div className="priorityChangeOption" onClick={()=>{updatePriority(1, this.state.activeTicket)}}>Priority 1</div>
                    <div className="priorityChangeOption" onClick={()=>{updatePriority(2, this.state.activeTicket)}}>Priority 2</div>
                    <div className="priorityChangeOption" onClick={()=>{updatePriority(3, this.state.activeTicket)}}>Priority 3</div>
                    <div className="priorityChangeOption" onClick={()=>{updatePriority(4, this.state.activeTicket)}}>Priority 4</div>
                    <div className="priorityChangeOption" onClick={()=>{updatePriority(5, this.state.activeTicket)}}>Priority 5</div>
                </div>
        
            </div>
        
                    
            <span className='closeModalCross' onClick={() => {closeModal("approvalSideBar")}}> X </span>
        </div>




        <div className="updateStatusContextMenu" id="contextMenu">
          <div className="contextHeader" id="contextHeader"></div>

          {this.state.devStatus.map((status) => 
                      <div key={status.id} className="contextOption" onClick={()=>{updateTicketState(status.id, status.description, this.state.activeTicket)}}>{status.description}</div>
          )}
          {this.state.contextTrelloId === "" && <>
            <div className="contextOption trelloOption" onClick={()=>{addToTrello(activeTicket)}}><FontAwesomeIcon icon={faTrello}/> Add To Trello</div>
            <div className="contextOption trelloOption" onClick={()=>{displayLinkToTrello(activeTicket)}}><FontAwesomeIcon icon={faTrello}/> Link To Trello</div>
          </>}

          
          <div className="contextOption contextComments" onClick={()=>{openCommentHistory()}}>Comments</div>
        </div>


        <div className="commentHistory" id="commentHistory">

                    <div className="commentHistoryHeader">
                      <p id="commentHistoryTicketId"></p>
                    </div>

                    <div className="commentHistoryAdd">
                      
                        <textarea id="commentTextArea" name="comment" cols="60" rows="10" required></textarea>
                        <br></br>
                        <button onClick={()=>{handleCommentSubmit()}} className="requestApprove">Submit</button>
                      
                      <hr></hr>
                      
                    </div>

                    <div className="commentHistoryComments">
                      {commentHistory.map((comment => (
                       <div className="commentRow">
                        <div className={"comment" + comment.type}>
                            <span className="commentDetails"><a className="clickable" onClick={()=>{inputUsername(comment.user)}}>@{comment.user}</a> - {new Date(comment.date).toLocaleDateString("en-UK")}:</span> {comment.comment}
                          </div>
                        </div>
                      )))}
                    </div>

                    <span className='closeModalCross' onClick={() => {closeModal("commentHistory")}}> X </span>

        </div>


        <div className="addToTrelloContainer" id="addTicketToTrello">
            <div className="addToTrelloForm">

                        <span className="closeTrelloAdd" onClick={()=>{document.getElementById("addTicketToTrello").style.display = "none"}}><FontAwesomeIcon icon={faClose} /></span>

              <h1>Add Ticket {activeTicket} to Trello <FontAwesomeIcon style={{"color":"#3D517B"}} icon={faTrello}/></h1>
                      

                        <form onSubmit={newTrelloTicket}>
                          <b>Card Title:</b><br></br>
                          <input name="" id="addTrelloDescription" className="addTrelloDescription" type="text" defaultValue={""} />
                          <br></br><br></br>
                          <textarea name="addTrelloContent" id="addTrelloContent" cols="145" rows="15"></textarea>

                          <select name="priorityFlag" id="priorityFlag">
                            <option value="1">Priority 1</option>
                            <option value="2" selected>Priority 2</option>
                            <option value="3">Priority 3</option>
                            <option value="4">Priority 4</option>
                            <option value="5">Priority 5</option>
                          </select>

                          <select name="assignee" id="assignee">
                          {trelloMembers.map((member)=>
                          <option value={member.id}>{member.fullName}</option>
                          )}
                          </select>
                        <br></br><br></br>
                          <button type="submit" id="createTrelloTicket">Create Trello Ticket &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faTrello}/></button>

                        </form>
            </div>
        </div>


        <div className="linkToTrelloContainer" id="linkTicketToTrello">
            <div className="linkToTrelloForm">
              <span className="closeTrelloAdd" onClick={()=>{document.getElementById("linkTicketToTrello").style.display = "none"}}><FontAwesomeIcon icon={faClose} /></span>
              <h1>Link Ticket {activeTicket} to Existing Trello Card <FontAwesomeIcon style={{"color":"#3D517B"}} icon={faTrello}/></h1>    
              
              {trelloCardsLoading && <>
                <img src="loading.gif" />
                <h3>Loading Trello Cards...</h3>
              </>}
              
              {!trelloCardsLoading && allTrelloCards
                .sort((a,b) => a.idShort < b.idShort ? 1 : -1)
                .map((card) => 
                  (
                    <div className="linkTrelloCard" onClick={()=>{linkTicketToTrelloCard(activeTicket, card.id, card.idShort)}}>
                      <b>{card.idShort}</b> - {card.name}
                    </div>
                  )
                )}
            </div>
        </div>


        <div className="deleteRequestContainer" id="deleteRequestContainer">
            <div className="deleteRequestForm">
                <div className="deleteRequestTitle">
                  <b>
                    <span id="deleteName"></span> has tried to close ticket <span id="deleteId"></span>. This was prevented as it has been approved.
                  </b>
                </div>

                <div className="deleteRequestCheckbox">
                <b>Note : A linked Trello ticket will also be deleted once the team member closes the support ticket.</b>
                </div>

                <div className="deleteRequestSubmit">
                  <button className="deleteRequestPermit" onClick={()=>{permitDelete(activeTicket)}}>
                    Permit Closure &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} /></button>
                  <button className="deleteRequestCancel" onClick={()=>{document.getElementById("deleteRequestContainer").style.display = "none"}}>
                    Cancel &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCancel} /></button>
                </div>
            </div>
        </div>



      </div>
      
    );
  }
}

export default Planner;
