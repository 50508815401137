import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Link,
  Image
} from "@react-pdf/renderer";
import axios from 'axios'
import Cookies from "js-cookie";
import { useState, useEffect } from "react";


// Create styles
const styles = StyleSheet.create({
  FrontPage: {
    paddingTop:100,
    backgroundColor: "#1a8cff",
    color: "white",
    textAlign:"center",
    
  },
  logo:{
    padding:100
  },
  headerLarge:{
    paddingBottom:10,
    fontSize: 22,
    fontWeight: "bolder"
  },
  headerSmall:{
    bottom:5,
    fontSize: 12,
    paddingTop:350,
    textAlign:"right",
    paddingRight:10
  },
  page: {
    backgroundColor: "#fff",
    color: "#000",
    paddingBottom:20
  },
  section: {
    margin: 10,
    paddingLeft: 20,
    fontSize:12
  },
  viewer: {
    width: "89vw", //the pdf viewer will take up all of the width and height
    height: "93vh",
  },
  pageTop:{
    backgroundColor:"#1a8cff",
    textAlign: "center",
    color:"#fff",
    padding:10
  },
  textHeader:{
    color:"#1a8cff",
    paddingBottom:5,
    fontSize:14
  },
  contentText:{
    paddingBottom:10,
    fontSize:14,
  },
  comment:{
    fontSize:11,
    color:"#000",
    paddingBottom:8,
    width:"100%",
    borderBottom:"1px solid #949494"
  },
  commentInfo:{
    paddingTop:2,
    paddingBottom:2,
    fontSize:10,
    color:"#949494"
  }
});



function BucketPDFView() {

  const [tickets, setTickets] = useState(Array())
  const [bucket, setBucket] = useState("");
  
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const selectedBucket =  queryParameters.get("bucket");
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const docdate = `${current.getDate()}_${current.getMonth()+1}_${current.getFullYear()}`;


  const fetchData = () => {
  const token = Cookies.get('_auth');
  const email = JSON.parse(Cookies.get('_auth_state')).email;
  const data = JSON.stringify({Token: token, Email: email, Data: {bucket: selectedBucket}});

  if (type === "bucket"){
  
  setBucket(bucket);
      axios.post('getBucketTickets.php', data)
      .then((res)=>{
          setTickets(res.data.tickets)
          console.log(bucket, res.data.tickets);
      })
      .catch((err)=>{
        alert(err);
      })   
    }

    if(type === "project"){

      // get project tickets, update variables etc etc
      axios.post('getProjectTickets.php', data)
      .then((res)=>{
          setTickets(res.data.tickets);
          setBucket(res.data.description);
          console.log(res.data);
      })
      .catch((err)=>{
        alert(err);
      })   
    }


  }

    useEffect(() => {
      fetchData();
  }, []);


  return (
    
    <PDFViewer style={styles.viewer} >
      <Document title={bucket + " - " + docdate}>
        {/*Render front page*/}
        <Page size="A4" style={styles.FrontPage}>
          <Image style={styles.logo} src="ospreylogowhite.png"/>
          <View style={styles.headerLarge}><Text>Osprey Approach Development Report</Text></View>
          <View style={styles.headerLarge}>
            <Text>{bucket}</Text>
          </View>
          <View style={styles.headerSmall}>
            <Text>Report Date: {date}</Text>
          </View>
        </Page>

        


        {tickets.map((ticket) => 
          
        <Page bookmark={"#" + ticket.id + " - " + ticket.subject} size="A4" style={styles.page}>
    
          <View style={styles.pageTop}>
            <Text id={"#" + ticket.id}>Ticket #{ticket.id} - Priority {ticket.priority} {ticket.type}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.textHeader}>Description:</Text>
            <Text>{ticket.subject}</Text>
          </View>

          {ticket.area !== "" && 
            <View style={styles.section}>
              <Text style={styles.textHeader}>Product Area:</Text>
              <Text>{ticket.area}</Text>
            </View>
          }
          <View style={styles.section}>
            <Text style={styles.textHeader}>Site URLs:</Text>
            {ticket.clients.split(";").map((url =>
            <Text>{url}</Text>
              ))}
          </View>

          {ticket.type === "investigation" && 
                  <View style={styles.section}>
                    <Text style={styles.textHeader}>Replicate on Test:</Text>
                    <Text>No</Text>
                  </View>
          }

          {ticket.type === "issue" && 
                  <View style={styles.section}>
                    <Text style={styles.textHeader}>Replicate on Test:</Text>
                    <Text>Yes</Text>
                  </View>
          }

          <View style={styles.section}>
            {ticket.type !== "wish" ? <Text style={styles.textHeader}>Steps to Replicate:</Text> : <Text style={styles.textHeader}>Request:</Text> }
            
            <Text>{ticket.steps}</Text>
          </View>

          {ticket.error !== "" && 
            <View style={styles.section}>
              <Text style={styles.textHeader}>Error:</Text>
              <Text>{ticket.error}</Text>
            </View>
          }
          {ticket.preferredOutcome !== "" && 
            <View style={styles.section}>
              <Text style={styles.textHeader}>Preferred/Expected Outcome:</Text>
              <Text>{ticket.preferredOutcome}</Text>
            </View>
          }
        
        </Page>

        )}

      </Document>
    </PDFViewer>
  );
}
export default BucketPDFView;