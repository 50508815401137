import { useState } from "react";
import axios from 'axios';


const addItem = () => {

    console.log("create new item")

}

const addToAgenda = () => {

    console.log("item added to agenda")

}


const AgendaArray = [
    {
        item: 1,
        description: "Here goeseth the description",
        ticket: 37759,
        devNotes: "Here goeseth the dev notes",
        supportNotes: "Here goeseth the support notes"
    },
    {
        item: 2,
        description: "Here goeseth the description",
        ticket: 37759,
        devNotes: "Here goeseth the dev notes",
        supportNotes: "Here goeseth the support notes"
    }

]





const NewMeeting = () => {


    const [agenda, setAgenda] = useState(AgendaArray);
    const [suggestedStartTicket, setSuggestedStartTickets] = useState(-4);
    const [suggestedTicketCount, setSuggestedTicketcount] = useState(0);
    const suggestedTicketsPerPage = 4;

        const [suggestedTickets, setSuggestedTickets] = useState(Array());
    
            //console.log(suggestedTickets)



    const initialiseSuggested = () => {
        setSuggestedStartTickets(1);
        let url = "getMeetingSuggested.php?offset=0&limit=" + suggestedTicketsPerPage;
        console.log(url)
        axios
            .get(url)
            .then((res =>
                {setSuggestedTickets(res.data.tickets)
                setSuggestedTicketcount(res.data.ticketCount)
                }

                ))
    }

        const updateSuggested = (start) => {
            setSuggestedStartTickets(start);
            let url = "getMeetingSuggested.php?offset=" + suggestedStartTicket + "&limit=" + suggestedTicketsPerPage;
            console.log(url)
            axios
                .get(url)
                .then((res =>{
                        setSuggestedTickets(res.data.tickets);
                        setSuggestedTicketcount(res.data.ticketCount)
                }))
        }


    function today(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        return(today)
    }

    return ( 
<div>

        <div className="mettingList">

            <div className="newMeetingMeta">
                <input type="date" id="meetingDate"/>
                <input type="text" id="description" placeholder="description"/>
            </div>


                            
{suggestedStartTicket > suggestedTicketCount || suggestedStartTicket < 0 && <div><button onClick={()=>{initialiseSuggested()}}>Start Suggested</button></div>}
{suggestedStartTicket && suggestedStartTicket > -1 &&
<div>

<div className="suggestedItemListHeader"><h4>Suggested Items of Conversation</h4></div>
                <div>
                <span className="suggestedItems">

                {suggestedStartTicket != 1 && <div className="suggestedPageNext oneRow" onClick={()=>{updateSuggested(suggestedStartTicket - 1)}}>prev</div>}

                {suggestedTickets.map((item =>
                    
                    <div key={item.id} className="suggestedItemCard oneRow">
                    <div className="suggestItemCardHeader">
                        {item.id}
                    </div>
                    <div className="suggestItemCardContent">
                     {item.description} - {item.bucket}
                    </div>
                </div>
                    
                    ))}

                    {suggestedStartTicket <= suggestedTicketCount - suggestedTicketsPerPage && <div className="suggestedPageNext oneRow" onClick={()=>{updateSuggested(suggestedStartTicket + 1)}}>next</div>}
                    
                </span>
                </div>

           </div>
                            
            }`


           


            <div className="meetingAddNewItem">
                <button onClick={()=>{addItem()}}>Add Custom Item</button>
            </div>


            <div className="agenda">

            {agenda.map((item => 

                    <div className="item" key={item.item}>
                        {item.item} - {item.ticket} = {item.description}
                    </div>



                ))}

            </div>





        </div>







        <div className="newItemForm">
            <form onSubmit={()=>{addToAgenda()}}>
                <input type="text" name="id" placeholder="Ticket ID or Description" />
                <input type="text" name="devInfo" placeholder="info/actions for development team" />
                <input type="text" name="otherInfo" placeholder="info/actions for other team" />
            </form>
        </div>





</div>


     );
}
 
export default NewMeeting;