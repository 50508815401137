const Home = () => {

    return ( 
        <div>
            <h2>Osprey Approach Development Planning</h2>
            <iframe title="dev_bi_board" width="100%" height="800px" src="https://app.powerbi.com/reportEmbed?reportId=3fa74460-325b-4cf1-95dd-dedec8b8fc1d&autoAuth=true&ctid=5625775e-192b-418a-8b26-e9f914a70fad" frameborder="0" allowFullScreen="true"></iframe>
        </div>
     );

}
 
export default Home;