import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faCalendar, faCircleCheck, faClock, faDiagramProject, faFlag, faGear, faHandSparkles, faHouse, faPlus, faRefresh, faSquareCheck, faStamp, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faTrello, faUikit } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
    return ( 
        <div>
            <div className="sidenav" id="mySideNav">
                <div className="logo">
                    <img src="https://hwchamber.co.uk/wp-content/uploads/2022/04/osprey-logo-colour-2019.png" alt="logo" />
                </div>
                <Link to="/"><FontAwesomeIcon icon={faHouse} />&nbsp;&nbsp;Overview</Link>
                <Link to="/Requests"><FontAwesomeIcon icon={faHandSparkles} />&nbsp;&nbsp;Requests</Link>
                <Link to="/Flagged"><FontAwesomeIcon icon={faFlag} />&nbsp;&nbsp;Flagged</Link>
                <Link to="/Approvals"><FontAwesomeIcon icon={faStamp} />&nbsp;&nbsp;Approvals</Link>
                <Link to="/Planning"><FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;Planning</Link>
                <Link to="/Projects"><FontAwesomeIcon icon={faDiagramProject} />&nbsp;&nbsp;Projects</Link>
                <Link to="/UI-UX"><FontAwesomeIcon icon={faUikit} />&nbsp;&nbsp;UI / UX</Link> 
                <Link to="/ReleaseData"><FontAwesomeIcon icon={faCircleCheck} />&nbsp;&nbsp;Complete</Link>
                
                

                {/*
                disabled nav links for now

                <Link to="/Releases"><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;Releases</Link>
                <Link to="/TimeLine"><FontAwesomeIcon icon={faClock} />&nbsp;&nbsp;Time Line</Link>
                <Link to="/Meetings"><FontAwesomeIcon icon={faBriefcase} />&nbsp;&nbsp;Meetings</Link>
                <Link to="/Users"><FontAwesomeIcon icon={faUsers} />&nbsp;&nbsp;Users</Link>
                <Link to="/Settings"><FontAwesomeIcon icon={faGear} />&nbsp;&nbsp;Settings</Link>

                */}

            </div>
            
            <Link className ="newCustomItemButton" to="/NewCustomItem"><button><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;New Custom Item</button></Link>
            <Link className ="newCustomItemButton1" to="/TrelloRefresh"><button><FontAwesomeIcon icon={faTrello} />&nbsp;&nbsp;Update Trello Data</button></Link>
        </div>
     );
}
 
export default Navbar;