import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Font } from "@react-pdf/renderer";
import { faArrowLeft, faArrowRight, faCalendar, faCalendarAlt, faComment, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ViewDevelopmentNotes = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)
    const [response, setResponse] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();
    const fetchData = () => {

        if(Cookies.get('_auth')){
            setAuthenticated(true);
        }

        const queryParameters = new URLSearchParams(window.location.search);
        const key = queryParameters.get("key");
        const id = queryParameters.get("id");
        const data = JSON.stringify({Key:key, Ticket:id});

        axios
            .post("getTrelloComments.php", data)
            .then((res) => {
                setLoading(false)
                if(res.data.error){
                    setError(res.data.error)
                    console.log("apparently there was an error")
                } else {
                    setResponse(res.data)
                    console.log(res.data)
                    console.log("c it again")
                }
                
            })
            .catch((err)=>{
                alert(err);
            })
            .finally(() => {
                
                console.log(response)
            })
    }       

    useEffect(() => {
        fetchData();
    }, []);



    console.log(response)

    return ( <>

     {loading && <>
            <div className="loadingTrelloComments">
                Loading...
            </div>
        </>}

        {error && <>
            <div className="addVotes noAuth">
                <div>
                    <img src="ospreylogowhite.png" style={{width:"400px"}}></img>
                </div><br></br><br></br>
                There was an error<br></br><br></br>{error}
            </div>
        </>}

        {response && !loading && !error && <>
                <div className="trelloCommentsPageContainer">
                <img src="ospreylogowhite.png" alt="osprey logo" style={{width:"15%", padding:"2vh"}}/>


                <div className="ticketData">


                

                {authenticated && <>
                    <div className="goBackToPlanner bluebutton" onClick={()=>navigate("/Planning", true)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;&nbsp;Back To Planner</div>
                </>}



                <div className="commentsTitle">Ticket Data</div>


                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Ticket Id #{response.ticket[0].id}
                                </div>
                            </div>

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Type - {response.ticket[0].type}
                                </div>
                            </div>

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Priority {response.ticket[0].priority}
                                </div>
                            </div>    

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Subject
                                </div>
                                <div className="itemData">
                                {response.ticket[0].subject}
                                </div>
                            </div>    

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Steps
                                </div>
                                <div className="itemData">
                                {response.ticket[0].steps}
                                </div>
                            </div>    

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Error
                                </div>
                                <div className="itemData">
                                {response.ticket[0].error}
                                </div>
                            </div>    
                             
                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Outcome
                                </div>
                                <div className="itemData">
                                {response.ticket[0].preferredOutcome}
                                </div>
                            </div>    

                            <div className="ticketDataContainer">
                                <div className="ticketDataTitle">
                                    Clients
                                </div>
                                <div className="itemData">
                                {response.ticket[0].clients}
                                </div>
                            </div>    
                    </div>


                    <div className="commentData">
                        <div className="commentsTitle">Development Events</div>
                        
                    
                    {response.comments.map((comment) => (<>
                        {comment.type === "comment" && <>
                            <div className="trelloComment">
                                <div className="trelloCommentUserInfo">
                                   <FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;&nbsp;&nbsp;{comment.user}
                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                   <FontAwesomeIcon icon={faCalendarAlt} />&nbsp;&nbsp;&nbsp;&nbsp;{comment.date}
                                </div>
                                <div className="trelloCommentText">
                                    {comment.comment}
                                </div>
                            </div>
                        </>}
                        {comment.type === "moveList" && <>
                            <div className="trelloMoveList">
                              <FontAwesomeIcon icon={faArrowRight} />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                                {comment.date} - {comment.user} move the card to the list "<b>{comment.comment}</b>"
                            </div>
                        </>}</>)
                    )}

                    </div>     
        
                </div>
            </>}

     

    </> );
}
 
export default ViewDevelopmentNotes;